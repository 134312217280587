
import { mapGetters } from 'vuex'
export default {
  name: 'SoldOut',
  components: {},
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  computed: {
    ...mapGetters(['offer'])
  }
}
