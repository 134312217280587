import { DateTime } from 'luxon'

export const SampleProofs = [
  {
    date: DateTime.local()
      .setZone('America/New_York')
      .plus({ hour: -2 })
      .toFormat('yyyy-LL-dd HH:mm:ss'),
    firstName: 'Allen',
    quantity: 1,
    productId: 17,
    city: 'Nashville',
    state: 'TN'
  },
  {
    date: DateTime.local()
      .setZone('America/New_York')
      .plus({ hour: -1 })
      .toFormat('yyyy-LL-dd HH:mm:ss'),
    firstName: 'Lt. Dan',
    quantity: 1,
    productId: 17,
    city: 'Danmark',
    state: 'Idanho'
  }
]
