
import { SampleProofs } from '../../documents/SampleData'

export default {
  name: 'SocialProof',
  props: {
    blok: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      snackbar: false,
      currentProof: 0,
      timeout: 8000,
      interval: 12000,
      purchases: [],
      date: null,
      proof: {
        firstName: null,
        city: null,
        state: null,
        productId: null,
        purchaseDate: null
      },
      x: null
    }
  },
  computed: {
    offer() {
      return this.$store.getters.offer(this.proof.productId)
    },
    src() {
      const image = this.blok.image || {}
      const url = (image.filename || '').replace('https://a.storyblok.com', '')
      return `${url}/m/`
    }
  },
  mounted() {
    if (!this.blok.enabled) {
      return
    }
    this.$salesApi
      .proof(this.$store.getters.offers.map(offer => offer.product.id))
      .then(data => {
        this.purchases =
          data.length < 1 && this.$config.environment !== 'production'
            ? SampleProofs
            : data

        this.x = setInterval(() => {
          const currentProof = this.currentProof
          const { date, firstName, city, state, productId } = this.purchases[
            currentProof
          ]

          this.proof.firstName = firstName
          this.proof.city = city
          this.proof.state = state
          this.proof.productId = productId
          this.proof.purchaseDate = date

          this.currentProof =
            currentProof < this.purchases.length - 1 ? currentProof + 1 : 0
          this.snackbar = true
        }, this.interval)
      })
  },
  beforeDestroy() {
    clearInterval(this.x)
  },
  methods: {
    snackbarDismiss: function() {
      this.snackbar = false
      this.currentProof = null
      clearInterval(this.x)
    }
  }
}
